$text-color: #282828;
$text-color-secondary: #414141;
$text-color-third: #757575;

$font-size: 16px;
$font-size-big: 24px;
$font-size-secondary: 14px;

$primary-color: #eda64b;
$secondary-color: #fff;

$screen-xs: 480px;
$screen-xs-min: $screen-xs;
// Extra small screen / phone

// Small screen / tablet
$screen-sm: 576px;
$screen-sm-min: $screen-sm;

// Medium screen / desktop
$screen-md: 768px;
$screen-md-min: $screen-md;

// Large screen / wide desktop
$screen-lg: 992px;
$screen-lg-min: $screen-lg;

// Extra large screen / full hd
$screen-xl: 1200px;
$screen-xl-min: $screen-xl;

// Extra extra large screen / large desktop
$screen-xxl: 1600px;
$screen-xxl-min: $screen-xxl;


@font-face {
  font-family: "samim";
  src: url("../assets/fonts/samim-font/Samim.eot");
  src: url("../assets/fonts/samim-font/Samim.woff") format("woff");
}

html, head, body, #root {
  min-height: 100%;
  height: 100%;
}

*{
  font-family: samim;
}

.alignEnd{
  text-align: end;
  width: 100%;
  justify-content: flex-end;
  padding-right: 20px;
}

.deleteIcon{
  color: red !important;
  cursor: pointer;
}
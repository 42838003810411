@import '../../theme/variables.scss';

.main{
  display: flex;
  flex-direction: row;
  min-height: 100%;

  .content{
    flex-grow: 1;
    // background-color: #f4f4f4;
    display: flex;
    padding: 30px;
  }

  .side{
    min-width: auto;
    width: auto;
    max-width: auto;
  }

  .side.collapsed{
    min-width: 256px;
    width: 256px;
    max-width: 256px;
  }

  .container{
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    max-width: 100%;

    .content{
      background-color: #eeeeee;
    }
  }
}

.header{
  height: 75px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding-right: 24px;
  padding-left: 24px;
  box-shadow: 0 6px 10px -5px rgb(0 0 0 / 30%);
  z-index: 1;

  .profileMenu{
    display: flex;
    align-items: center;
  }

  .profile{
    display: flex;
    align-items: center;
  }

  
}
.logoContainer{
  display: flex;
  justify-content: center;
  align-items: center;
}

.logo{
  // height: 100%;
  width: 50%;
}

.footer{
  min-height: 30px;
  text-align: center;
  display: flex;
  justify-content: center;
  flex-direction: row;
  align-items: center;
  padding-right: 24px;
  padding-left: 24px;

  .copywrite{
    font-size: 14px;
    color: #757575;
  }

  .menu{
    line-height: 75px;
    margin-left: auto;
  }
}

@media only screen and (max-width: $screen-xl) {
  
}

@media only screen and (max-width: $screen-lg) {
  .main{
    .side {
      position: fixed;
      top: 0;
      right: 0;
      bottom: 0;
      z-index: 10000;
    }

    .side > ul{
      min-width: 0;
      max-width: 0;
      width: 0;
    }

    .side.collapsed > ul{
      min-width: 256px;
      max-width: 256px;
      width: 256px;
    }
  }
}

@media only screen and (max-width: $screen-md) {
  
}

@media only screen and (max-width: $screen-sm) {
  
}

@media only screen and (max-width: $screen-xs) {
  .main{
    .content{
      padding: 10px;
    }
  }
}
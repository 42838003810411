@import '../../../theme/variables.scss';

.container{
  margin-top: 50px;
}

.cardContainer{
  box-shadow: 0 2px 10px 0px #dbdbdb;
  border-radius: 10px;
  padding: 30px;

  &:hover{
    box-shadow: 0 5px 10px 0px #bebebe;
  }
}

.introCompanyContainer, .cardContainer{
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 300px;

  img{
    width: 100%;
  }

  h2{
    font-weight: bold;
    margin-top: 30px;
    margin-bottom: 10px;
  }

  .firstSpan{
    margin-top: 20px;
  }

  p{
    margin-top: 20px;
    font-weight: bold; 
  }
}

@media only screen and (max-width: $screen-xl) {
  
}

@media only screen and (max-width: $screen-lg) {

}

@media only screen and (max-width: $screen-md) {
  
}

@media only screen and (max-width: $screen-sm) {
  
}

@media only screen and (max-width: $screen-xs) {
  .introCompanyContainer, .cardContainer{
    width: 100%;
    max-width: 100%;
  }
}
@import '../../theme/variables.scss';

.container{
  width: 400px;
  // background-color: $secondary-color !important;
}

@media only screen and (max-width: $screen-xl) {
  
}

@media only screen and (max-width: $screen-lg) {

}

@media only screen and (max-width: $screen-md) {
  
}

@media only screen and (max-width: $screen-sm) {
  
}

@media only screen and (max-width: $screen-xs) {
  .container{
    width: 90%;
    margin: 5%;
    // background-color: $secondary-color !important;
  }
}
.container{
  background-image: url('../../assets/images/ship2.png');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  flex-grow: 1;
  display: flex;
  justify-content: center;
  align-items: center;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}
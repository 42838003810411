@import '../../theme/variables.scss';

.main{
  display: flex;
  flex-direction: column;
  min-height: 100%;

  .content{
    flex-grow: 1;
    // background-color: #f4f4f4;
    display: flex;
  }
}

.header{
  height: 110px;
  background-color: $secondary-color;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding-right: 24px;
  padding-left: 24px;
  box-shadow: 0 6px 16px 10px rgba(0, 0, 0, 0.3);
  z-index: 1;

  .logoContainer{
    display: flex;
    align-items: center;
    // margin-right: 210px;
    cursor: pointer;

    .logo{
      height: 100px;
      margin-right: 8px;
    }

    .logoType{
      font-size: 20px;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.4;
      letter-spacing: normal;
      color: #b417eb;
      margin-bottom: 0;
    }
  }

  .actionsContainer{
    display: flex;
    align-items: center;

    .action{

    }

    .firstAction{
      margin-right: 30px;
    }
  }
}

.footer{
  min-height: 30px;
  text-align: center;
  background-color: $secondary-color;
  display: flex;
  justify-content: center;
  flex-direction: row;
  align-items: center;
  padding-right: 24px;
  padding-left: 24px;

  .copywrite{
    font-size: 14px;
    color: #757575;
  }

  .menu{
    line-height: 75px;
    margin-left: auto;
  }
}


@media only screen and (max-width: $screen-xl) {
  
}

@media only screen and (max-width: $screen-lg) {

}

@media only screen and (max-width: $screen-md) {
  
}

@media only screen and (max-width: $screen-sm) {
  
}

@media only screen and (max-width: $screen-xs) {
  .header{
    .actionsContainer{
      &>button:first-child{
        margin-bottom: 10px;
        margin-right: 0;
      }
      &>button{
        width: 170px;
      }
      flex-direction: column;
      justify-content: center;
    }
  }
}
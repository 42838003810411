.downloadBtn {
  color: "#eda64b";
  font-family: "samim";
}

.uploadBtn {
  color: "#eda64b";
  font-family: "samim";
  border: "1px solid #eda64b";
}

.myGrid {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 20%;
  margin: 0 auto;
}

.myGrid2 {
  width: 20%;
  margin: 0 auto;
}
